import React, { useState, useEffect } from "react";
import { NFTStorage, File } from "nft.storage";
import { Buffer } from "buffer";
import { ethers } from "ethers";
import axios from "axios";
import ModelSelection from "./ModelSelection";
import { models } from "./models";

// Components
import Spinner from "react-bootstrap/Spinner";
import Navigation from "./components/Navigation";

// ABIs
import NFT from "./abis/NFT.json";

// Config
import config from "./config.json";

function App() {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [nft, setNFT] = useState(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [url, setURL] = useState(null);
  const [selectedModel, setSelectedModel] = useState(models[0]);

  const [message, setMessage] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const attribute_value = selectedModel ? selectedModel.attribute : null;

  const loadBlockchainData = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(provider);

    const network = await provider.getNetwork();

    console.log("config:", config);
    console.log("network:", network);
    console.log("network.chainId:", network.chainId);
    console.log("config[network.chainId]:", config[network.chainId]);

    const nft = new ethers.Contract(
      config[network.chainId].nft.address,
      NFT,
      provider
    );
    setNFT(nft);

    const name = await nft.name();
    console.log("name", name);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (name === "" || description === "") {
      window.alert("Please provide a name and description");
      return;
    }

    setIsWaiting(true);

    // Call AI API to generate an image based on a description
    await createImage();

    setIsWaiting(false);
    setMessage("");
  };

  const mintHandler = async (e) => {
    e.preventDefault();

    if (!image) {
      window.alert("Please generate an image before minting");
      return;
    }

    setIsWaiting(true);

    // Upload image to IPFS (NFT.Storage)
    const url = await uploadImage(image);

    // Mint NFT
    await mintImage(url);

    setIsWaiting(false);
    setMessage("");

    console.log("Success!");
  };

  const createImage = async () => {
    console.log("Generating image...");
    setMessage("Generating Image...");

    var final_description = attribute_value
      ? attribute_value + " " + description
      : description;

    // You can replace this URL with different model API's
    const URL = selectedModel.url;

    const response = await axios({
      url: URL,
      method: "POST",
      headers: {
        Authorization: "Bearer " + process.env.REACT_APP_HUGGING_FACE_API_KEY,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        inputs: final_description,
        options: { wait_for_model: true },
      }),
      responseType: "arraybuffer",
    });

    const type = response.headers["content-type"];
    const data = response.data;

    const base64data = Buffer.from(data).toString("base64");
    const img = `data:${type};base64,${base64data}`; //  <- to render it on a page
    setImage(img);

    return data;
  };

  const uploadImage = async (base64Image) => {
    console.log("Uploading image...");
    setMessage("Uploading image...");

    // Create Blob from base64 image
    const response = await fetch(base64Image);
    const blob = await response.blob();

    // Create instance to NFT.Storage
    const nftstorage = new NFTStorage({
      token: process.env.REACT_APP_NFT_STORAGE_API_KEY,
    });

    // Send request to store image
    const { ipnft } = await nftstorage.store({
      image: new File([blob], "image.jpeg", { type: "image/jpeg" }),
      name: name,
      description: description,
    });

    // Save URL
    const url = `https://ipfs.io/ipfs/${ipnft}/metadata.json`;
    setURL(url);

    return url;
  };

  const mintImage = async (tokenURI) => {
    console.log("waiting for mint...");
    setMessage("Waiting for Mint...");

    const signer = await provider.getSigner();
    const transaction = await nft
      .connect(signer)
      .mint(tokenURI, { value: ethers.utils.parseUnits("0.01", "ether") });
    await transaction.wait();
  };

  useEffect(() => {
    loadBlockchainData();
  }, []);

  return (
    <div>
      <Navigation account={account} setAccount={setAccount} />

      <div className="form">
        <form>
          <input
            type="text"
            placeholder="Create a name..."
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Create a description..."
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <input type="button" onClick={submitHandler} value="Generate Image" />
          <input type="button" onClick={mintHandler} value="Mint NFT" />
        </form>

        <div className="image">
          {!isWaiting && image ? (
            // eslint-disable-next-line
            <img src={image} alt="AI generated image" />
          ) : isWaiting ? (
            <div className="image__placeholder">
              <Spinner animation="border" />
              <p>{message}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {!isWaiting && url && (
        <p>
          View&nbsp;
          <a href={url} target="_blank" rel="noreferrer">
            Metadata
          </a>
        </p>
      )}
      <div className="models">
        <ModelSelection setSelectedModel={setSelectedModel} />
      </div>
    </div>
  );
}

export default App;
