export const models = [
    {
        name: 'InkPunk AI model',
        description: 'Prompt example: evil chihuahua mobster',
        url: 'https://api-inference.huggingface.co/models/Envvi/Inkpunk-Diffusion',
        attribute: 'nvinkpunk, best quality,',
    },
    {
        name: 'SOMEthing AI model',
        description: 'Prompt example: 1girl, aqua eyes, baseball cap, blonde hair, closed mouth, earrings, green background, hat, hoop earrings, jewelry, looking at viewer, shirt, short hair, simple background, solo, upper body, yellow shirt',
        url: 'https://api-inference.huggingface.co/models/NoCrypt/SomethingV2_2',
        attribute: 'masterpiece, best quality, negative space, (bioluminescence:1.2), darkness, dark background,',
    },
    {
        name: 'Vintedois AI model',
        description: 'Prompt example: kneeling cat knight, portrait, finely detailed armor, intricate design, silver, silk, cinematic lighting, 4k; Prompt example: blonde, girl, demon, dark, city',
        url: 'https://api-inference.huggingface.co/models/22h/vintedois-diffusion-v0-1',
        attribute: 'estilovintedois,',
    },
    {
        name: 'Openjourney v4 AI model',
        description: 'Prompt example:',
        url: 'https://api-inference.huggingface.co/models/prompthero/openjourney-v4',
        attribute: 'mdjrny-v4 style, masterpiece, best quality,',
    },
    {
        name: 'Redshift AI model',
        description: 'Prompt example: Ford mustang',
        url: 'https://api-inference.huggingface.co/models/nitrosocke/redshift-diffusion',
        attribute: 'redshift style,',
    },
    {
        name: 'Arcane AI model',
        description: 'Prompt example: a close up portrait photo of 26 y.o cute woman in wastelander clothes, long haircut, pale skin, slim body, background is city ruins',
        url: 'https://api-inference.huggingface.co/models/nitrosocke/Arcane-Diffusion',
        attribute: 'arcane style,',
    },
    {
      name: 'Openjourney AI model',
      description: 'Prompt example:',
      url: 'https://api-inference.huggingface.co/models/prompthero/openjourney',
      attribute: 'mdjrny-v4 style, masterpiece, best quality,',
    },
    {
      name: 'Stable diffusion 2.0 AI model',
      description: 'Prompt example:',
      url: 'https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-2',
      attribute: '4k style, masterpiece, best quality,',
    },
    {
      name: 'Realistic Vision AI model',
      description: 'Prompt example: a close up portrait photo of 26 y.o woman in wastelander clothes, long haircut, pale skin, slim body, background is city ruins',
      url: 'https://api-inference.huggingface.co/models/SG161222/Realistic_Vision_V1.4',
      attribute: '(high detailed skin:1.2), 8k uhd, dslr, soft lighting, high quality, film grain, Fujifilm XT3,',
    },
    {
      name: 'Waifu Anime AI model',
      description: 'Prompt example: 1girl, green hair, sweater, looking at viewer, upper body, beanie, outdoors, watercolor, night, turtleneck',
      url: 'https://api-inference.huggingface.co/models/hakurei/waifu-diffusion',
      attribute: 'masterpiece, best quality,',
    },
];
