import React, { useState } from "react";
import "./index.css";
import { models } from "./models";

const ModelSelection = ({ setSelectedModel }) => {
  const selectedStyle = {
    backgroundColor: "#008CBA", // Change this to any color you prefer
  };

  const selectedModelStyle = {
    backgroundColor: "#aeabfc", // Light blue
  };

  const [selectedButton, setSelectedButton] = useState(null); //This will change the button style when the model is selected
  const [selectedModelUrl, setSelectedModelUrl] = useState(null); //This will change the background color when model is selected

  return (
    <div>
      <h2 className="model-header">
        Choose an AI model to generate the image:
      </h2>
      {models.map((model, index) => (
        <div
          key={index}
          className="model-detail"
          style={selectedModelUrl === model.url ? selectedModelStyle : null}
        >
          <h2>{model.name}</h2>
          <p>{model.description}</p>
          <button
            onClick={() => {
              setSelectedModel({
                url: model.url,
                attribute: model.attribute,
              });
              setSelectedButton(model.url); // assuming url is unique for each model
              setSelectedModelUrl(model.url); // assuming url is unique for each model
            }}
            className="model-button"
            style={selectedButton === model.url ? selectedStyle : null}
          >
            Select this model
          </button>
        </div>
      ))}
    </div>
  );
};

export default ModelSelection;
